
.content {
    display: flex;
    flex-direction: column;  
    justify-content: center;
    align-items: center;
    width: 100%; 
}

.options {
    display: flex;
    flex-direction: column;
    padding: 1em;
}

.main_button {
    padding: 1em;
    border: 0px none;
    border-radius: 0.5em;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 4px;
    cursor: pointer;  
    font-size: 1em;
    outline:none;
    margin-bottom: 1em; 
    background-color: #6c757d;
    color: white;
    transition: background .3s ease; 
}  

.main_button:hover {
    background-color:#6c757dd0;
}

.complete {
    background-color: #28a745;
}

.complete:hover {
    background-color:#28a746d0;
}
    
.back {
    background-color: #dc3545!important; 
    transition: background .3s ease; 
}
.back:hover {
    background-color: #ff456ad0!important;
}

.text-area { 
    border: 0px none;
    border-radius: 0.5em;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 4px;
    cursor: pointer;
    font-weight: bold;
    font-size: 1.2em;
    outline:none;
    width: 100%;
    resize: none;
}

.divInput {
    width:100%; 
    margin: 1em 0;
    text-align: center;
}

.divInputAutocomplete {
    width:100%; 
    margin: 1em 0;
    text-align: center;
}

.divInputBotones {
    width: 100%;
    margin: 1em 0;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.divInputSelect {
    width:100%; 
    margin: 1em 0;
    text-align: center;
}

.select {
    width: 100%;
    border: 0px none;
    padding: 1em;
    border-radius: 0.5em;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 4px;
    font-weight: bold;
    font-size: 1.2em;
}

hr {
    width: 100%;
    height: 100%;
    border: 1px solid #343a40;
    margin: 1em 0;
}

.titulo_seccion {
    width: 100%;
    text-align: center;
    font-weight: bold;
    padding: 1em;
    color: #343a40;
}
 
.iconRight { 
    display: none;
}

.iconLeft {
    display: none;
}

.imc_descripcion {
    padding: 1em;
    font-weight: bold;
    color: #02B0ED;
}

.MuiMenuItem-root {
    white-space: normal!important;
}

.swal2-container {
    z-index: 2222!important;
}

.MuiTabs-vertical {
    flex-direction: column;
    min-width: 10em; 
}

.MuiTabs-scroller {
    flex: 1 1 auto;
    display: inline-block;
    position: relative;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dropIcon{
    right: 5px;
    top: 5px;
    color: #dc3545;
    position: absolute;
    cursor: pointer;
}

.showIcon{
    right: 5px;
    top: 5px;
    color: #28a745;
    position: absolute;
    cursor: pointer;
}

@media (max-width: 600px) { 

    .options {
        flex-direction: row;
        align-items: center;
    }

    .main_button {
        min-width: 10em;
        margin: 0 0.5em;
        min-height: 5em;
    }

    .divInput {
        min-width: 15em;
        margin: 0 1em;
    }

    .divInputAutocomplete {
        min-width: 20em;
        margin: 0 1em;
    }

    .divInputBotones {
        justify-content: flex-start;
    }
    
    .divInputSelect{
        min-width: 15em;
        max-width: 15em;
        margin: 0 1em;
    }

    .content {
        flex-direction: row;
    }

    .titulo_seccion { 
        writing-mode: vertical-rl;
        transform: rotate(180deg);
        padding: 0em 1em 0em 1em;
    }

    hr { 
        margin: 0 0.5em;
        height: 55%;
        width: 0%;
    }

    .iconRight { 
        display: initial;
        bottom: 25px;  
        position: fixed;
        right: 25px;
        z-index: 5;    
        color: #00000059;
    }

    .iconLeft {
        display: initial;
        bottom: 25px; 
        position: fixed;
        left: 25px; 
        z-index: 5;
        color: #00000059;
    }
}