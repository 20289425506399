.MuiIconButton-root { 
    padding: 5px!important; 
}

.buttonsMedico {
    display: flex;
    flex-direction: column;
}

.buttonsMedico > button:first-child {
    margin-bottom: 5px;
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .buttonsMedico {
        display: flex;
        flex-direction: row;
    }

    .buttonsMedico > button:first-child {
        margin-right: 5px;
        margin-bottom: 0px;
    }
}